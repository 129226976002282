.agency-page-myjobs {
    table.job-table {
        width: 100%;

        .job-table-status {
            display: flex;
            justify-content: start;
            align-items: center;
        }

        thead {
            background-color: #eee !important;
        }

        th {
            white-space: nowrap;
            border: none;
            font-weight: 500;
            font-size: 16px;
            padding: 10px 15px;
        }

        tbody>tr {
            border-bottom: 1px solid #ECEDF2;
        }

        td {
            vertical-align: middle;
            padding: 15px 15px;
            border: none;
        }

        .job-table-info-content-title {
            font-size: 16px;
            margin: 0px;
            word-wrap: break-word;
            max-width: 100%;
            font-weight: 700;
            line-height: 1.5;

            a {
                color: #000;
            }

            a:hover {
                color: var(--color-primary);
            }
        }


        .title-wrapper {
            margin-bottom: 0px;
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .nowrap {
            white-space: nowrap;
        }

        .action-button a,
        .action-button button {
            color: var(--color-primary);
            height: 30px;
            line-height: 30px;
            text-align: center;
            width: 30px;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            display: inline-block;
            background-color: #ededed;
            -webkit-transition: all 0.3s ease-in-out 0s;
            -o-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
            font-size: 16px;
            position: relative;
        }

        .action-button {
            display: flex;
            gap: 5px;
        }

    }
}

.job-metas {
    >* {
        color: #696969;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
    }

    .location>* {
        margin-top: 0px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
    }
}

.my-recent-applications,
.my-opportunities {
    .job-table-status {
        display: table-cell !important;
    }
}