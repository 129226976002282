.profile-header {

    background: #f6f6f6;
    padding: 68px 0;

    .creative-details {
        align-items: start;
    }

    .cat-link {
        color: rgb(105, 105, 105);
        font-size: 20px;
        margin-left: 10px;

        &:hover {
            color: black;
        }
    }

    .tags {
        margin-top: 10px;
        display: flex;
        gap: 10px;
    }

    .badge {
        background: black;
        font-size: 13px;
        font-weight: 300;
        padding: 8px 20px;
        border-radius: 50px;
    }

    .open-jobs {
        background: rgba(0, 0, 0, 0.15);
        display: inline-block;
        font-size: 20px;
        margin-top: 10px;
        padding: 5px 20px;
        border-radius: 30px;
    }

    .meta-item {
        font-size: 18px;
    }

    .position {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .avatar {

        &.rounded img {
            border-radius: 100%;
            object-fit: cover;
        }
    }

    .meta {
        margin-left: 15px;
        float: left;
    }

    .username {
        font-size: 18px;
    }

    .job-location.location>* {
        // margin-top: 5px;
        font-size: 16px;
        font-weight: 400;
    }

    // .job-location.location span, .job-location.location a {
    //     color: rgb(105, 105, 105) !important;
    // }

    span.featured-text {
        font-size: 13px;
        color: rgb(9, 0, 112);
    }

    .actions {
        gap: 10px;
        flex-wrap: wrap !important;
    }

    @media screen and (max-width: 767px) {
        .avatar {
            width: 100px;

            &.rounded img {
                width: 100px;
                height: 100px;
            }
        }

        & .meta {
            margin-left: 0;
            margin-top: 15px;
        }
    }
}

@media (max-width: 1024px) and (min-width:900px) {
    .profile-header {
        padding-top: 95px;
    }
}