.content-title {
    font-weight: normal;
    margin: 30px 0;
    font-size: 2.3rem;
}

.subtitle {
    font-weight: bold;
    margin-bottom: 20px;
}

.content {
    font-size: 19px;
}

.content-section {
    img {
        width: 100%;
    }

    .no-preivew {
        width: 300px;
        background: #eeeeee;
        padding: 15px;
        border-radius: 40px;
    }
}

.portfolio-item {

    &:hover {
        .overlay {
            opacity: 1;
            transform: scale(1);
        }
    }

    img {
        border-radius: 10px;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        overflow: hidden;
        inset: auto 0 0 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
        transition: all 0.2s ease;
    }
}

.content-list {

    .content {
        padding-left: 60px;
        padding-bottom: 20px;
        position: relative;
    }

    .circle {
        background-color: #000;
        color: var(--color-primary);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        line-height: 30px;
        text-align: center;
        left: 2px;
        position: absolute;
        top: 8px;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .top-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
    }

    span.edu_stats {
        font-size: 16px;
        font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-weight: 600;
        color: #202124;
    }

    span.year {
        color: #000;
        font-size: 14px;
    }

    .work_experience {
        .edu_stats {
            font-size: 20px;
        }

        span.year {
            span {
                padding: 0px 5px;
            }
        }
    }

    .content:after {
        border-left: 2px dashed rgba(25, 103, 210, 0.15);
        content: "";
        height: calc(100% - 52px);
        width: 2px;
        left: 5px;
        position: absolute;
        top: 37px;
    }

    .content:last-child:after {
        border: 0;
    }

    span.university {
        font-size: 16px;
        font-weight: 600;
    }
}

.realted-creatives-list-container {
    margin: 20px 0;
}