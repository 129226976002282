.profile-sidebar {

    .sidebar-item {
        padding: 30px;
        background: #eee !important;
        border-radius: 8px;
        // width: 100%;
        overflow: auto;
    }

    .item {
        display: flex;
        gap: 15px;
        margin-bottom: 25px;

        // .details {
        //     .value {
        //         word-break: break-all;
        //     }
        // }
    }




    .text {
        font-size: 16px;
        font-weight: 500;
        color: #202124;
    }

    .value {
        font-size: 20px;
        overflow-wrap: break-word;

        span {
            font-size: 20px;
            font-weight: 400;
        }
    }

    .title {
        margin-bottom: 18px;
    }

    .video-section video {
        width: 100%;
        border-radius: 5px;
    }

    .btn-dark:hover {
        background-color: rgba(0, 0, 0, 0.07);
        border-color: transparent;

        a {
            color: #fff !important;

        }
    }
}

@media (max-width: 1024px) {
    .profile-sidebar .value, .profile-sidebar .value span {
        font-size: 14px;
        font-weight: 400;
    }
}