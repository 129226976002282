.ql-editor {
    font-family: "JOST", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; 
    font-size: 14pt;
}
.ql-editor a, .ql-mention-denotation-char {
    color: #d3a11f; 
    cursor: pointer;
} 
.ql-editor a:hover {
    color: #000;
}
.ql-toolbar.ql-snow {
    border-radius: 10px 10px 0 0;
}
.ql-container.ql-snow {
    border-radius:  0 0 10px 10px;
}
.ql-mention-list-container {
    background: #000;
    color: #fff;
    font-family: 'Jost';
    font-size: 14pt;
    border-radius: 4px;
    border: 1px solid #000;
    overflow: hidden;
    z-index: 99;
}
.ql-mention-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    cursor: pointer;
}
.ql-mention-list-item {
    padding: 5px 15px;
}
.ql-mention-list-item.selected {
    background: #fff;
    color: #000;
}
.ql-editor-container {
    height: calc( 100% - 50px);
}
.mention-thumbnail {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    color: #000;
    font-weight: 500;
    margin-right: 5px;
}
.ql-mention-list-item.selected .mention-thumbnail {
    background: #000;
    color: #fff;
}
.mention-item {
    display: flex;
    align-items: center;
}
@media (max-width: 550px) {
    .ql-editor-container {
        height: calc( 100% - 95px);
    }
}