.post-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 60%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
    min-height: 80%;
    display: flex;
    flex-direction: column;
    overflow: visible;

    .postmodal-header {
        display: flex;
        align-items: center;
        gap: 10px;
    }


    .postmodal-body {
        margin-top: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        [contenteditable="true"] {
            flex: 1;

            &:focus-visible {
                outline: none;
            }

            &:empty:before {
                content: attr(placeholder);
                display: block;
                color: #858585;
                cursor: text;
            }
        }

        .toggle-emo {
            display: inline-block;
            cursor: pointer;
        }

        .post-options {
            margin-top: 20px;
            position: relative;
            align-items: center;
            flex-wrap: wrap;

            .item {
                background: #f3f2f0;
                margin-right: 10px;
                padding: 10px;
                font-size: 20px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                text-align: center;
                cursor: pointer;
            }

            .item:hover {
                box-shadow: rgba(140, 140, 140, 0.2) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 4px 4px 0px;
            }

            .post-attachments {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                max-height: 50px;

                .post-attachment {
                    position: relative;
                    margin-top: -20px;

                    img {
                        width: 75px;
                        min-width: 75px;
                        max-width: 75px;
                        height: 75px;
                        min-height: 75px;
                        max-height: 75px;
                        object-fit: cover;
                        border: 2px solid var(--color-primary);
                        margin: 5px
                    }

                    video {
                        width: 75px;
                        min-width: 75px;
                        max-width: 75px;
                        height: 75px;
                        min-height: 75px;
                        max-height: 75px;
                        object-fit: contain;
                        border: 2px solid var(--color-primary);
                        margin: 5px
                    }

                    svg {
                        font-size: 24px;
                        font-weight: 900;
                        color: black;
                        cursor: pointer;
                        position: absolute;
                        top: -6px;
                        right: -6px;
                    }

                    svg:hover {
                        font-size: 24px;
                        color: var(--color-primary);
                    }
                }
            }
        }


        aside.EmojiPickerReact.epr-main {
            position: absolute;
            top: -10px;
        }

    }


    .postmodal-footer {

        .btn-post {
            background: #d3a11f;
            color: white;
            padding: 5px 20px;
            border-radius: 20px;
            min-width: max-content;
        }

        .postmodal-action {
            text-align: right;
        }

        .btn-post:hover {
            background-color: #b98c16;
            color: white;
        }
    }

}


.post-item {
    .post-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        div {
            flex-basis: 25%;
            padding: 5px;
        }

        div:first-child {
            flex-basis: 100%;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .video-container {
            /* width is set as 100% here. any width can be specified as per requirement */
            // width: 100%;
            // padding-top: 56.25%;
            //height: 0px;

            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--black, #000);
            color: var(--white, #fff);
            overflow: hidden;
            width: 100%;
            max-width: 100%;
            height: auto;
            padding-top: 0;
            max-height: calc(100vh - 90px);

            .video {
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
    }
}

.postmodal-input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
}

.postmodal-input:focus {
    border: 1px solid darkgray;
}

// @media (max-width: 820px) {
//     .video-container {
//         height: 50vh !important;
//     }
// }

.post-modal {
    position: relative;
}

.post-modal .tagger {
    position: absolute;
    width: 250px;
    height: max-content;
    background-color: #d3a11f;
    border-radius: 3px;
    // border: 1px solid #000;
    left: calc(50% - 125px);
    top: 200px;
    z-index: 1;
}

.post-modal .tagger input {
    width: 100%;
    height: 36px;
    background: #000;
    color: #fff;
}

.post-modal .tagger .tagger-preview {
    width: 100%;
    height: 36px;
}

.post-modal .tagger .tagger-dropdown {
    width: 100%;
    overflow: hidden;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.post-modal .tagger .tagger-dropdown .tagger-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 3px;
}

.post-modal .tagger .tagger-dropdown .tagger-item img {
    border-radius: 100%;
}

.post-modal .tagger .tagger-dropdown .tagger-item:hover,
.post-modal .tagger .tagger-dropdown .tagger-item:focus {
    background-color: #d3a11f;
    color: #000;
}

.post-modal .tagger .tagger-exit {
    position: absolute;
    font-size: 18pt;
    cursor: pointer;
    right: -16px;
    top: -16px;
}

.post-preview {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    margin-bottom: 10px !important;
}

@media (min-width: 1440px) {
    .post-images {
        max-width: 560px;
        margin: 0 auto;
    }
}